import React from "react"
import reactImg from "./img/react.png"
import tailwindcssImg from "./img/tailwindcss.png"
import wordpressImg from "./img/wordpress.png"
import gatsbyImg from "./img/gatsby.png"
import netlifyImg from "./img/netlify.png"
import bootstrapImg from "./img/bootstrap.png"
import "./App.css"

function App() {
  const date = new Date()
  return (
    <div className="App">
      <header className="App-header p-4">
        <div className="p-5 text-gray-700 gold-border border-2">
          <div className="text-xl sm:text-3xl font-bold">SERGIO CUTONE</div>
          <div className="text-xs sm:text-lg">SENIOR WEB DEVELOPER</div>
        </div>
      </header>
      <div className="max-w-screen-lg mx-auto px-10 py-6">
        <div className="grid sm:grid-cols-2 gap-4">
          <a
            href="https://portfolio.sergiocutone.com/"
            className="mb-4 sm:m-0 rounded-lg shadow-xl p-10 hover:bg-gray-200 border-b-2 gold-border transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <div className="text-lg font-bold">PORTFOLIO</div>
              <div>Custom Wordpress</div>
            </div>
            <img
              src={wordpressImg}
              className="w-12 mx-auto inline"
              alt="Wordpress"
              title="Wordpress"
              width="48"
              height="48"
            />
            <img
              src={bootstrapImg}
              className="w-12 mx-auto inline"
              alt="Bootstrap"
              title="Bootstrap"
              width="48"
              height="48"
            />
          </a>
          <a
            href="https://netlify.sergiocutone.com/"
            className="rounded-lg shadow-xl p-10 hover:bg-gray-200 border-b-2 gold-border transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110"
            target="_blank"
            rel="noreferrer"
          >
            <div>
              <div className="text-lg font-bold">PORTFOLIO</div>
              <div>Gatsby Headless Wordpress</div>
            </div>
            <img
              src={gatsbyImg}
              className="w-12 mx-auto inline"
              alt="Gatsby"
              title="Gatsby"
              width="48"
              height="48"
            />
            <img
              src={wordpressImg}
              className="w-12 mx-auto inline"
              alt="Wordpress"
              title="Wordpress"
              width="48"
              height="48"
            />
            <img
              src={netlifyImg}
              className="w-12 mx-auto inline"
              alt="Netlify"
              title="Netlify"
              width="48"
              height="48"
            />
          </a>
        </div>
      </div>
      <footer className="p-10">
        <p className="font-bold mb-1">©{date.getFullYear()} Sergio Cutone</p>
        <p>
          <img
            src={reactImg}
            alt="React"
            title="React"
            className="mx-auto inline w-12"
            width="48"
            height="48"
          />
          <img
            src={tailwindcssImg}
            alt="Tailwind CSS"
            title="Tailwind CSS"
            className="mx-auto inline w-12"
            width="48"
            height="48"
          />
        </p>
      </footer>
    </div>
  )
}

export default App
